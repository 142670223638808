import React, { useState } from 'react';
import { Input, Button, notification, Radio } from 'antd';
import { useNavigate } from 'react-router-dom';

const Dalle = () => {
  const [prompt, setPrompt] = useState('');
  const [size, setSize] = useState('1024x1024');
  const [imageUrl, setImageUrl] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const navigate = useNavigate();

  const handlePromptChange = (event) => {
    setPrompt(event.target.value);
  };

  const handleSizeChange = (event) => {
    setSize(event.target.value);
  };

  const handleImageRequest = async () => {
    // Disable the button
    setButtonDisabled(true);
  
    const token = localStorage.getItem('token');
    if (!token) {
      notification.error({
        message: '授权错误 Authentication Error',
        description: '用户尚未登陆，跳转至登陆页面。User not logged in. Redirecting to login page.'
      });
      navigate('/login');
      return;
    }
  
    const response = await fetch(`${process.env.REACT_APP_API_ROOT_URL}/service/dalle/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Token ${token}`
      },
      body: `prompt=${encodeURIComponent(prompt)}&size=${size}`
    });
  
    if (response.ok) {
      const data = await response.json();
      setImageUrl(data.image_url);
      notification.success({
        message: '成功 Success',
        description: '图片生成请求成功 Request posted successfully!'
      });
      // Disable button for 30 seconds
      setTimeout(() => {
        setButtonDisabled(false);
      }, 30000);
    } else if (response.status === 403) { // Handle 403 Forbidden
      notification.error({
        message: 'Session Expired',
        description: 'Your session has expired. Please log in again.'
      });
      navigate('/login');
      return;
    } else {
      const errorData = await response.json();
  
      if (errorData.error && errorData.error.includes("safety system")) {
        notification.error({
          message: '内容审查 Forbidden Content',
          description: '您的请求可能包含禁止的内容，例如色情、暴力或其他敏感主题。 请修改您的描述并重试。 Your request contains forbidden content such as porn, violence, or other sensitive subjects. Please modify your description and try again.'
        });
      } else {
        notification.error({
          message: 'Error',
          description: errorData.error || 'An error occurred.'
        });
      }
      setButtonDisabled(false); // Enable the button again if there was an error
    }
  };
  

  return (
    <div style={{ width: '100%', maxWidth: '800px', margin: '16px auto', position: 'relative' }}>
      <Button style={{ position: 'absolute', top: '16px', right: '16px' }} onClick={() => navigate("/dashboard")}>
        返回主页 Dashboard
      </Button>
      
      <h2>DALL·E 图片生成 Image Generator</h2>
      
      <Input.TextArea
        placeholder="在这里描述你想要的图片 Enter a description for the image..."
        value={prompt}
        onChange={handlePromptChange}
        autoSize={{ minRows: 3, maxRows: 5 }}
        style={{ marginBottom: '16px' }}
      />
        
      <div>
        <label style={{ marginRight: '8px' }}>选择图片尺寸 Select Image Size:</label>
        <Radio.Group onChange={handleSizeChange} value={size} style={{ marginBottom: '16px' }}>
            <Radio.Button value="256x256">256x256</Radio.Button>
            <Radio.Button value="512x512">512x512</Radio.Button>
            <Radio.Button value="1024x1024">1024x1024</Radio.Button>
        </Radio.Group>
      </div>

      <div style={{ marginBottom: '16px', borderTop: '1px solid #e8e8e8' }}></div>

      <Button type="primary" onClick={handleImageRequest} disabled={buttonDisabled}>
        生成图片 Generate Image
      </Button>
        
      {imageUrl && (
        <div style={{ marginTop: '16px' }}>
          <img src={imageUrl} alt="Generated from DALL·E" style={{ width: '100%', height: 'auto', maxWidth: size.split('x')[0] + 'px' }} />
          <a href={imageUrl} target="_blank" rel="noopener noreferrer" style={{ display: 'block', marginTop: '8px' }}>
            新窗口打开图片 Open in new window or download
          </a>
        </div>
      )}
    </div>
  );
};

export default Dalle;
