import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Alert, Select, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import './SignupForm.css';

const { Option } = Select;

const SignupForm = () => {
    const [formData, setFormData] = useState({
        username: '',
        phone_number: '',
        password: '',
        passwordConfirm: '',
        prefix: '+86',
        verificationCode: '',
    });
    const [errorMsg, setErrorMsg] = useState(null);
    const [disableCodeBtn, setDisableCodeBtn] = useState(false);
    const [countdown, setCountdown] = useState(60);
    const navigate = useNavigate();

    useEffect(() => {
        let timer;
        if (disableCodeBtn) {
            timer = setInterval(() => {
                setCountdown((prev) => {
                    if (prev <= 1) {
                        clearInterval(timer);
                        setDisableCodeBtn(false);
                        return 60;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [disableCodeBtn]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSignup = () => {
        if (formData.password !== formData.passwordConfirm) {
            setErrorMsg('两次输入的密码不一致');
            return;
        }
    
        const signupData = {
            username: formData.username,
            phone_number: formData.prefix + formData.phone,
            password: formData.password,
            verificationCode: formData.verificationCode,
        };
    
        axios.post(`${process.env.REACT_APP_API_ROOT_URL}/accounts/signup/`, signupData)
            .then(res => {
                if (res.data.token) {
                    localStorage.setItem('token', res.data.token);
                    navigate('/dashboard');
                }
            })
            .catch(err => {
                setErrorMsg('用户注册失败');
            });
    };
    
    const handleSendCode = () => {
        const phone_number = formData.prefix + formData.phone;
    
        axios.post(`${process.env.REACT_APP_API_ROOT_URL}/accounts/sendcode/`, { phone_number })
            .then(res => {
                if (res.status === 200) {
                    // Code was sent successfully
                    setDisableCodeBtn(true);
                }
            })
            .catch(err => {
                // Failed to send the code
                setErrorMsg('Failed to send verification code');
            });
    };

    const handleNavigateToLogin = () => {
        navigate('/login');
    };
    

    return (
        <Form className="signup-form">
            {errorMsg && <Alert message={errorMsg} type="error" />}
            <Form.Item
                label="用户名"
                name="username"
                rules={[{ required: true, message: 'Please input your username!' }]}
            >
                <Input name="username" onChange={handleChange} />
            </Form.Item>

            <Form.Item
                label="手机号"
                name="phone"
                rules={[{ required: true, message: 'Please input your phone number!' }]}
            >
                <Row gutter={8}>
                    <Col span={6}>
                        <Select defaultValue="+86" style={{ width: '100%' }} onChange={(value) => setFormData({ ...formData, prefix: value })}>
                            <Option value="+1">+1</Option>
                            <Option value="+86">+86</Option>
                            {/* Add more country codes as needed */}
                        </Select>
                    </Col>
                    <Col span={10}>
                        <Input name="phone" onChange={handleChange} placeholder="Phone Number" />
                    </Col>
                    <Col span={8}>
                        <Button onClick={handleSendCode} disabled={disableCodeBtn}>
                            Send Code {disableCodeBtn && `(${countdown})`}
                        </Button>
                    </Col>
                </Row>
            </Form.Item>

            <Form.Item
                label="验证码"
                name="verificationCode"
                rules={[{ required: true, message: 'Please input your verification code!' }]}
            >
                <Input name="verificationCode" onChange={handleChange} />
            </Form.Item>

            <Form.Item
                label="密码"
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Input.Password name="password" onChange={handleChange} />
            </Form.Item>

            <Form.Item
                label="确认密码"
                name="passwordConfirm"
                rules={[{ required: true, message: 'Please confirm your password!' }]}
            >
                <Input.Password name="passwordConfirm" onChange={handleChange} />
            </Form.Item>

            <Form.Item>
                <Button type="primary" onClick={handleSignup} style={{ marginRight: '10px' }}>
                    注册
                </Button>
                <Button onClick={handleNavigateToLogin}>
                    已有账号
                </Button>
            </Form.Item>
        </Form>
    );
};

export default SignupForm;
