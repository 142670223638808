import React, { useState } from 'react';
import { Input, Button, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

function PaymentComponent() {
    const navigate = useNavigate();
    const [amount, setAmount] = useState('');
    const [subject, setSubject] = useState('源子AI服务余额充值');

    const [amountError, setAmountError] = useState(null);

    const handleAmountChange = (e) => {
        const value = e.target.value;

        // Check if the input is empty
        if (value === '') {
            setAmount('');
            setAmountError(null);
            return;
        }

        const floatValue = parseFloat(value);
        
        // Check for more than two decimal places
        if (Math.floor(floatValue * 100) !== floatValue * 100) {
            setAmountError('金额只保留两位小数，即0.01人民币。Only up to two decimal places are allowed.');
            return;
        }
        
        // Check for negative values
        if (floatValue < 0) {
            setAmountError('金额应为正数。Value should be positive.');
            return;
        }

        setAmount(value);
        setAmountError(null);
    };

    const handleSubjectChange = (e) => {
        setSubject(e.target.value);
    };

    const handleSubmit = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                notification.error({
                message: 'Authentication Error',
                description: 'User not logged in. Redirecting to login page.'
                });
                navigate('/login');
                return;
            }
    
            // Construct the URL with parameters
            const apiUrl = `${process.env.REACT_APP_API_ROOT_URL}/payment/alipay-test/?total_amount=${amount}&subject=${subject}`;
    
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                }
            });
    
            if (response.ok) {
                const data = await response.json();
                if (data.redirectUrl) {
                    window.location.href = data.redirectUrl;
                } else {
                    notification.error({
                        message: 'Payment Failed',
                        description: 'Expected redirect URL was not provided by the backend.'
                    });
                }
            } else {
                notification.error({
                    message: 'Payment Failed',
                    description: 'An error occurred while processing your payment.'
                });
            }
        } catch (error) {
            notification.error({
                message: 'Payment Error',
                description: 'An unexpected error occurred. Please try again later.'
            });
        }
    };
    

    return (
        <div style={{ width: '300px', margin: '0 auto', marginTop: '100px' }}>
            <h2>使用支付宝支付 Pay with Alipay</h2>
            <Input 
                type="number" 
                placeholder="输入元/人民币金额 Input in RMB"
                value={amount}
                onChange={handleAmountChange}
                style={{ marginBottom: '10px' }}
            />
            {amountError && <p style={{color: 'red', marginBottom: '10px'}}>{amountError}</p>}
            <Input 
                type="text"
                placeholder="Enter Subject"
                value={subject}
                onChange={handleSubjectChange}
                style={{ marginBottom: '20px' }}
            />
            <Button type="primary" onClick={handleSubmit} block>
                支付宝支付 Pay with Alipay
            </Button>
        </div>
    );
}

export default PaymentComponent;
