import React, { useState } from 'react';
import { Card, Form, Input, Button, Select, notification, Row, Col } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

const LoginForm = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [isPhoneLogin, setIsPhoneLogin] = useState(false);
    const [prefix, setPrefix] = useState('+86');
    const [disableCodeBtn, setDisableCodeBtn] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');


    const navigate = useNavigate();

    const handleLogin = () => {
        axios.post(`${process.env.REACT_APP_API_ROOT_URL}/accounts/login/`, { username, password })
            .then(res => {
                if (res.data.token) {
                    localStorage.setItem('token', res.data.token);
                    navigate('/dashboard');
                }
            })
            .catch(err => {
                notification.error({
                    message: 'Login Failed',
                    description: 'Invalid username or password'
                });
            });
    };

    const handlePhoneLogin = () => {
        axios.post(`${process.env.REACT_APP_API_ROOT_URL}/accounts/login2/`, { phone_number: prefix+phone, verification_code: verificationCode })
            .then(res => {
                if (res.data.token) {
                    localStorage.setItem('token', res.data.token);
                    
                    navigate('/dashboard');
                }
            })
            .catch(err => {
                notification.error({
                    message: 'Login Failed',
                    description: 'Invalid phone number or verification code'
                });
            });
    };



    const handleSendCode = () => {
        const phone_number = prefix + phone;
    
        axios.post(`${process.env.REACT_APP_API_ROOT_URL}/accounts/sendcode/`, { phone_number })
            .then(res => {
                if (res.status === 200) {
                    // Code was sent successfully
                    setDisableCodeBtn(true);
                }
            })
            .catch(err => {
                // Failed to send the code
                setErrorMsg('Failed to send verification code');
            });
    };

    return (
        <Card title="登陆" style={{ maxWidth: '500px', margin: '0 auto' }}>
            {!isPhoneLogin ? (
                <Form>
                    <Form.Item
                        label="用户名"
                        name="username"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input onChange={(e) => setUsername(e.target.value)} />
                    </Form.Item>

                    <Form.Item
                        label="密码"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password onChange={(e) => setPassword(e.target.value)} />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" onClick={handleLogin}>
                            登陆
                        </Button>
                        <Button type="link" onClick={() => setIsPhoneLogin(true)}>
                            手机号登陆
                        </Button>
                        <Button type="link" onClick={() => navigate('/signup')}>
                            还没有账号
                        </Button>
                    </Form.Item>
                </Form>
            ) : (
                <Form>
                    <Form.Item
                        label="手机号"
                        name="phone"
                        rules={[{ required: true, message: 'Please input your phone number!' }]}
                    >
                        <Row gutter={8}>
                            <Col span={6}>
                                <Select defaultValue="+86" style={{ width: '100%' }} onChange={(value) => setPrefix(value)}>
                                    <Option value="+1">+1</Option>
                                    <Option value="+86">+86</Option>
                                    {/* Add more country codes as needed */}
                                </Select>
                            </Col>
                            <Col span={10}>
                                <Input onChange={(e) => setPhone(e.target.value)} placeholder="Phone Number" />
                            </Col>
                            <Col span={8}>
                                <Button disabled={disableCodeBtn} onClick={handleSendCode}>发送验证码</Button>

                            </Col>
                        </Row>
                    </Form.Item>

                    <Form.Item
                        label="验证码"
                        name="verificationCode"
                        rules={[{ required: true, message: 'Please input your verification code!' }]}
                    >
                        <Input onChange={(e) => setVerificationCode(e.target.value)} />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" onClick={handlePhoneLogin}>
                            登陆
                        </Button>
                        <Button type="link" onClick={() => setIsPhoneLogin(false)}>
                            使用账户密码登陆
                        </Button>
                        <Button type="link" onClick={() => navigate('/signup')}>
                            还没有账号
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </Card>
    );
};

export default LoginForm;
