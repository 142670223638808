import React, { useState, useEffect } from 'react';
import { Input, Button, notification } from 'antd';
import QRCode from 'qrcode.react';
import { useNavigate } from 'react-router-dom';

function WeChatPaymentComponent() {
    const navigate = useNavigate();
    const [amount, setAmount] = useState('');
    const [subject, setSubject] = useState('源子AI服务余额充值');
    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [amountError, setAmountError] = useState(null);
    const [buttonCooldown, setButtonCooldown] = useState(false);
    const [paymentConfirmed, setPaymentConfirmed] = useState(false);

    const [outTradeNo, setOutTradeNo] = useState(''); // Step 1

    useEffect(() => {
        let timer;
        if (buttonCooldown) {
            timer = setTimeout(() => {
                setButtonCooldown(false);
            }, 30000); // 30 seconds cooldown
        }

        return () => clearTimeout(timer); // Cleanup function to clear the timer if the component is unmounted
    }, [buttonCooldown]);

    const handleAmountChange = (e) => {
        const value = e.target.value;

        if (value === '') {
            setAmount('');
            setAmountError(null);
            return;
        }

        const floatValue = parseFloat(value);
        
        if (Math.floor(floatValue * 100) !== floatValue * 100) {
            setAmountError('金额只保留两位小数，即0.01人民币。Only up to two decimal places are allowed.');
            return;
        }

        if (floatValue < 0) {
            setAmountError('金额应为正数。Value should be positive.');
            return;
        }

        setAmount(value);
        setAmountError(null);
    };

    const handleSubjectChange = (e) => {
        setSubject(e.target.value);
    };

    const handleSubmit = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                notification.error({
                    message: 'Authentication Error',
                    description: 'User not logged in. Redirecting to login page.'
                });
                navigate('/login');
                return;
            }

            const apiUrl = `${process.env.REACT_APP_API_ROOT_URL}/payment/wechatpay-initiate/?total_amount=${amount}&subject=${subject}`;

            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                }
            });

            if (response.ok) {
                const data = await response.json();
                if (data.code === 200) {
                    const parsedMessage = JSON.parse(data.message);
                    setQrCodeUrl(parsedMessage.code_url);
                    setButtonCooldown(true); // Activate button cooldown
                    setOutTradeNo(data.out_trade_no); // Step 2: Capture out_trade_no
                } else {
                    notification.error({
                        message: 'Payment Failed',
                        description: data.message
                    });
                }
            } else {
                notification.error({
                    message: 'Payment Failed',
                    description: 'An error occurred while processing your payment.'
                });
            }
        } catch (error) {
            notification.error({
                message: 'Payment Error',
                description: 'An unexpected error occurred. Please try again later.'
            });
        }
    };

    const handlePaymentConfirmation = () => {
        setPaymentConfirmed(true);
        navigate(`/wechatpay-success?out_trade_no=${outTradeNo}`); // Step 3: Use out_trade_no for navigation
    };

    return (
        <div style={{ width: '300px', margin: '0 auto', marginTop: '100px' }}>
            <h2>使用微信支付 Pay with WeChat</h2>
            <Input 
                type="number" 
                placeholder="输入元/人民币金额 Input in RMB"
                value={amount}
                onChange={handleAmountChange}
                style={{ marginBottom: '10px' }}
            />
            {amountError && <p style={{color: 'red', marginBottom: '10px'}}>{amountError}</p>}
            <Input 
                type="text"
                placeholder="Enter Subject"
                value={subject}
                onChange={handleSubjectChange}
                style={{ marginBottom: '20px' }}
            />
            <Button type="primary" onClick={handleSubmit} block disabled={buttonCooldown}>
                微信支付 Pay with WeChat
            </Button>

            {qrCodeUrl && !paymentConfirmed && (
                <div style={{ marginTop: '20px', textAlign: 'center' }}>
                    <QRCode value={qrCodeUrl} />
                    <p>请使用微信扫描上方二维码进行支付</p>
                    <Button onClick={handlePaymentConfirmation} block>
                        确认已支付 Confirm Payment
                    </Button>
                </div>
            )}
        </div>
    );
}

export default WeChatPaymentComponent;
