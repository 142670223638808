import React from 'react';
import { Result, Button } from 'antd';

const UnderDevelopment = () => {
    return (
        <div style={{ textAlign: 'center', padding: '50px' }}>
            <Result
                status="warning"
                title="正在开发 Under Development"
                subTitle="很抱歉，这一功能正在开发中，请一些天后再来查看。Sorry, this page is currently under development. Please check back later!"
                extra={
                    <Button type="primary" href="/dashboard">返回主页 Back to Home</Button>
                }
            />
        </div>
    );
};

export default UnderDevelopment;
