import React, { useEffect, useState } from 'react';
import { Button, Card, Divider, Space, Modal, List, Typography, Descriptions } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';



const ProfileDashboard = () => {
    const [user, setUser] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');

        axios.get(`${process.env.REACT_APP_API_ROOT_URL}/accounts/profile/`, {
            headers: {
                'Authorization': `Token ${token}`
            }
        })
        .then(response => {
            setUser(response.data);
        })
        .catch(error => {
            console.error('Error fetching user profile:', error);
            if (error.response && error.response.status === 401) {
                navigate('/login');
            }
        });
        
    }, []);

    const handleLogout = () => {
        setIsModalVisible(true);
    };

    const confirmLogout = () => {
        const token = localStorage.getItem('token');
        axios.post(`${process.env.REACT_APP_API_ROOT_URL}/accounts/logout/`, {}, {
            headers: {
                'Authorization': `Token ${token}`
            }
        })
        .then(() => {
            localStorage.removeItem('token');
            navigate('/login'); // Navigate to login page after logout
        })
        .catch((error) => {
            console.error('Error logging out:', error);
        });
    };

    const handleServiceClick = (path) => {
        navigate(path);
    };

    const data = [
        { label: 'GPT4 对话服务', path: '/gpt4', price: '0.6元每1000字' },
        { label: 'ChatGPT 对话服务', path: '/chat', price: '0.02元每1000字' }, // Assuming prices are per hour for this example
        { label: 'Dall-E 图片生成', path: '/dalle', price: '0.2元每张' },
        
        //...you can add more items here...
    ];

    return (
        <Space direction="vertical" size="middle" style={{ width: '100%', margin: '20px' }}>
            {/* User Info */}
            

            <Card title="用户信息 UserInfo" bordered={true} style={{ width: '100%', marginBottom: '20px' }}>
                <Descriptions column={1} layout="horizontal" bordered>
                    <Descriptions.Item label="用户名 Username">{user.username}</Descriptions.Item>
                    
                    <Descriptions.Item label="电话 Phone">{user.phone_number}</Descriptions.Item>
                    <Descriptions.Item label="余额/元 Wallet/¥">{user.wallet_balance}</Descriptions.Item>
                    {/* Add more fields as needed */}
                </Descriptions>
            </Card>


            <Divider />

            {/* Service Choices */}
            <Card title="选择服务" style={{ width: '100%' }}>
                <List
                    bordered
                    dataSource={data}
                    renderItem={item => (
                        <List.Item 
                            key={item.label} 
                            onClick={() => handleServiceClick(item.path)}
                        >
                            {item.label} <div style={{ float: 'right' }}>{item.price}</div>
                        </List.Item>
                    )}
                />
            </Card>

            <Divider />

            {/* Action buttons */}
            <Space direction="vertical" size="middle">
                <Button onClick={() => navigate('/alipay')}>支付宝充值 Alipay</Button>
                <Button onClick={() => navigate('/wechatpay')}>微信支付充值 WechatPay</Button>
                <Button onClick={() => navigate('/payment-record')}>查看支付记录 View Payment Records</Button> {/* Placeholder button */}
            </Space>

            <Divider />

            <Button type="danger" onClick={handleLogout}>退出当前账号 Logout</Button>


            {/* Logout Modal */}
            <Modal 
                title="Confirm Logout" 
                visible={isModalVisible} 
                onOk={confirmLogout} 
                onCancel={() => setIsModalVisible(false)}
            >
                <p>你确定退出当前账号吗？ Are you sure you want to log out?</p>
            </Modal>
        </Space>
    );
};

export default ProfileDashboard;
