import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';  // Import the useNavigate hook

const WeChatPaySuccessPage = () => {
    const [paymentSuccessful, setPaymentSuccessful] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();  // Initialize the navigate function

    useEffect(() => {
        const checkPaymentStatus = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const out_trade_no = urlParams.get('out_trade_no');

            try {
                const response = await axios.get(`${process.env.REACT_APP_API_ROOT_URL}/payment/wechatpay-callback/`, {
                    params: { out_trade_no: out_trade_no }
                });
                
                if (response.data.result) {
                    setPaymentSuccessful(true);
                }
            } catch (error) {
                console.error("Error checking WeChat payment status:", error);
            } finally {
                setLoading(false);
            }

            // Set a timer to navigate to the dashboard for both success and failure after 3 seconds
            setTimeout(() => {
                navigate('/dashboard');
            }, 3000);
        };

        checkPaymentStatus();
    }, [navigate]);

    if (loading) {
        return <div>正在加载 Loading...</div>;
    }

    return (
        <div>
            {paymentSuccessful ? (
                <>
                    <h2>支付成功 Payment Successful!</h2>
                    <p>您使用微信成功充值服务，感谢您的订单！ Your payment with WeChat was successful. Thank you for your purchase!</p>
                </>
            ) : (
                <>
                    <h2>支付失败 Payment Failed</h2>
                    <p>您使用微信支付发生了一些问题，导致支付失败。请联系相关技术支持。There was an issue with your WeChat payment. Please try again or contact support.</p>
                </>
            )}
        </div>
    );
};

export default WeChatPaySuccessPage;
