import React, { useState, useEffect } from 'react';
import { Table, Button, notification } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';  // <-- Import the useNavigate hook

const Orders = () => {
    const navigate = useNavigate();  // <-- Get the navigate function using the hook

    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });
    const [loading, setLoading] = useState(false);

    const columns = [
        {
            title: '订单号',
            dataIndex: 'out_trade_no',
            key: 'out_trade_no',
        },
        {
            title: '产品或服务名称',
            dataIndex: 'subject',
            key: 'subject',
        },
        {
            title: '订单状态',
            dataIndex: 'order_status',
            key: 'order_status',
            render: (order_status) => (order_status === 0 ? '未付款' : '已付款'),
        },
        {
            title: '总金额',
            dataIndex: 'total_amount',
            key: 'total_amount',
        },
        {
            title: '创建时间',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: '更新时间',
            dataIndex: 'updated_at',
            key: 'updated_at',
        },
    ];

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const token = localStorage.getItem('token');
                const result = await axios.get(`${process.env.REACT_APP_API_ROOT_URL}/payment/orders/?page=${pagination.current}`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });
    
                setData(result.data.results);
                setPagination(prevPagination => ({
                    ...prevPagination,
                    total: result.data.count,
                }));
                
            } catch (error) {
                console.error("Error fetching data:", error);
                if (error.response && error.response.status === 401) {
                    // Unauthorized, likely due to expired token
                    notification.error({
                        message: '登录已过期',
                        description: '请重新登录.',
                    });
                    navigate('/login');  // <-- Use the navigate function here
                }
            }
            setLoading(false);
        };
        fetchData();
    }, [pagination.current]); // <-- Only trigger when the current page number changes
    


    const handleTableChange = (pagination) => {
        setPagination(pagination);
    };

    return (
        <div>
            <Button
                type="primary"
                style={{ marginBottom: 16 }}
                onClick={() => navigate("/dashboard")}  // <-- Use the navigate function here
            >
                返回主页
            </Button>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />
        </div>
    );
};

export default Orders;
