import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Carousel, Button } from 'antd';
import './styles.css';

function Home() {
  const navigate = useNavigate();

  useEffect(() => {
    const keydownHandler = () => navigate('/chat');
    window.addEventListener('keydown', keydownHandler);
    return () => {
      window.removeEventListener('keydown', keydownHandler);
    };
  }, [navigate]);

  return (
    <div className="container">
      <Carousel effect="fade" autoplay className="carousel" dots={false} autoplaySpeed={2000}>
        <Card bordered={false} className="language-card">
          <h1>你好， 这里是源子，很高兴认识你！</h1>
        </Card>
        <Card bordered={false} className="language-card">
          <h1>我能为你做什么？试试用输入任何字符-使用对话服务。</h1>
        </Card>
        <Card bordered={false} className="language-card">
          <h1>我也可以为按您的要求生成图片。</h1>
        </Card>
      </Carousel>

      <div className="auth-buttons">
        <Button type="primary" ghost onClick={() => navigate("/login")}>登陆</Button>
        <Button type="primary" ghost onClick={() => navigate("/signup")}>注册</Button>
        <Button type="primary" ghost onClick={() => window.open("http://wiki.yuantsy.com", "_blank")}>知识树</Button>
      </div>
    </div>
  );
}

export default Home;
