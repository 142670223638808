import React, { useState, useEffect } from 'react';
import { List, Input, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';

const backButtonStyle = {
  position: 'absolute',
  top: '16px',
  right: '16px',
};

const initialContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '800px',
  margin: '16px auto',
};

const messageListStyle = {
  flexGrow: 1,
  overflow: 'auto',
  marginBottom: '16px',
};

const inputAreaStyle = {
  display: 'flex',
  alignItems: 'flex-end',
  width: '100%',
};

const itemStyle = {
  margin: '16px 0',
};

const textStyle = {
  color: 'black',
};

function Chat() {
  const [inputValue, setInputValue] = useState("");
  const [messages, setMessages] = useState([
    { role: 'system', content: '你好， 这里是源子，有什么可以帮您？' },
  ]);

  const navigate = useNavigate();


  const [containerStyle, setContainerStyle] = useState(initialContainerStyle); 

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 800) {
        setContainerStyle({
          ...initialContainerStyle,
          maxWidth: '96%',
        });
      } else {
        setContainerStyle(initialContainerStyle);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = async () => {
    if (inputValue !== '') {
      const token = localStorage.getItem('token');
      if (!token) {
        notification.error({
          message: 'Authentication Error',
          description: 'User not logged in. Redirecting to login page.'
        });
        navigate('/login');
        return;
      }
  
      setMessages([...messages, { role: 'user', content: inputValue }]);
      
      const response = await fetch(`${process.env.REACT_APP_API_ROOT_URL}/service/chatbot/`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',  // Modify this line to 'application/json'
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify({
          message: inputValue,
          chatbot_messages: messages
        })
      });
      
      if (response.status === 400) {
        const errorData = await response.json();

        if (errorData.error === 'Insufficient balance.') {
          notification.error({
            message: 'Insufficient Balance',
            description: 'Please add funds to your wallet.'
          });
          return;
        } else if (errorData.error === 'Wallet not found for user.') {
          notification.error({
            message: 'Wallet Error',
            description: 'Wallet not found for user. Please contact support.'
          });
          return;
        }
      } else if (response.status === 401 || response.status === 403) {
        let errorDescription = 'An error occurred. Please try again later.';

        if (response.status === 401) {
          errorDescription = 'Invalid token. Redirecting to login page.';
          localStorage.removeItem('token'); // remove invalid token for 401
        } else if (response.status === 403) {
          errorDescription = 'Forbidden access. Redirecting to login page.';
        }

        notification.error({
          message: 'Error',
          description: errorDescription
        });

        if (response.status === 401 || response.status === 403) {
          navigate('/login');
        }
      } else {
        const data = await response.json();
        setMessages(prevMessages => [...prevMessages, { role: 'assistant', content: data.response }]);
        
        setInputValue(""); // clear the input after submit
      }
    }
  };
  


  const handleContent = (content) => {
    return content.split('\n').map((text, i) => (
      <p key={i} style={textStyle}>{text}</p> 
    ));
  }

  return (
    <div style={containerStyle}>
      <Button style={backButtonStyle} onClick={() => navigate("/dashboard")}>返回主页</Button>

      <div style={{width: '100%'}}>
        <List
          style={messageListStyle}
          itemLayout="horizontal"
          dataSource={messages}
          renderItem={message => (
              <List.Item style={itemStyle}>
                  <List.Item.Meta
                      title={<span style={textStyle}>{message.role === 'user' ? 'You' : '源子'}</span>} 
                      description={<span style={{whiteSpace: 'pre-wrap'}}>{handleContent(message.content)}</span>}
                  />
              </List.Item>
          )}
        />
      </div>
      <div style={inputAreaStyle}>
        <Input.TextArea
          style={{flexGrow: 1}}
          placeholder="在这里输入..."
          value={inputValue}
          onChange={handleInputChange}
          autoSize
        />
        <Button type="primary" onClick={handleSubmit}>
          发送
        </Button>
      </div>
    </div>
  );
}

export default Chat;
