import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import UnderDevelopment from './components/UnderDevelopment';

import Login from './components/Login';
import SignUp from './components/SignUp'
import ProfileDashboard from './components/Dashboard';

import Orders from './components/Orders'
import AlipayPage from './components/AlipayPage';
import SuccessPage from './components/AlipaySuccessPage'
import WeChatPaymentComponent from './components/Wechatpay';
import WeChatPaySuccessPage from './components/WechatpaySuccessPage';

import Chat from './components/Chat'
import Dalle from './components/DallE';
import GPT48k from './components/GPT4-8k';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/todo" element={<UnderDevelopment />} />

        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<ProfileDashboard />} />
        
        <Route path="/payment-record" element={<Orders />} />
        <Route path="/alipay" element={<AlipayPage />} />
        <Route path="/alipay-success" element={<SuccessPage />} />
        <Route path="/wechatpay" element={<WeChatPaymentComponent />} />
        <Route path="/wechatpay-success" element={<WeChatPaySuccessPage />} />


        <Route path="/chat" element={<Chat />} />
        <Route path="/gpt4" element={<GPT48k />} />
        <Route path="/dalle" element={<Dalle />} />
      </Routes>
    </Router>
  );
}

export default App;
